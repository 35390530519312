import { useEffect, useState } from 'react';
import '../App.css'
import NavBar from './parts/navbar';
const Home = ()=>{
    const [first, setfirst] = useState(20)
    const [days, setdays] = useState()
    const [res, setres] = useState()
    const [eax, setexa] = useState()
    const [left, setleft] = useState(20)

useEffect(()=>{
    let today=new Date();
    var date1 = new Date();
    var date2 = new Date("08/01/2023");
    var date3 = new Date("08/18/2024");
    var date4 = new Date("06/30/2024");
    // 1
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    // 2
    var Difference_In_Time2 = date3.getTime() - date1.getTime();
    var Difference_In_Days2 = Difference_In_Time2 / (1000 * 3600 * 24);
    setres(parseInt(Difference_In_Days2))
    // 3
    var Difference_In_Time3 = date4.getTime() - date1.getTime();
    var Difference_In_Days3 = Difference_In_Time3 / (1000 * 3600 * 24);
    setexa(parseInt(Difference_In_Days3))
    setdays(parseInt(Difference_In_Days))
    setleft(365 - Difference_In_Days )
    var s = 365 - Difference_In_Days ;
    var ss = (s/365)*100
    console.log(ss)
    setfirst(parseInt(ss))
},[])

    return(
<div className="max-w-[100vw] bga h-[96rem] ">

<NavBar />
<div className="text-center">
<div className="grid place-items-center  h-screen -mt-32">
    <div className="radial-progress b  text-accent  inter" style={{ "--value": first, "--size": "14rem", "--thickness": "1.5rem" }}>{first}%</div>

</div>
<div className="grid justify-center">

<div class=" inter text-[#fff] text-left   -mt-[37vh]">
        {days} Left 
        <br/>
        {parseInt(left)} have passed
        <br/>
        {res} until results
        <br/>
        
        {eax} until the first exam
        </div>
</div>
    </div>

</div>
    )
}
export default Home;